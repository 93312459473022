import { CategoryItem } from "./models/index";
import { END_POINT } from "configs/endpoint";
import { ILogin, ICeo, ITeamInfo, Categories } from "./models";
import { request } from "./request";

export const loginService = async (data: ILogin) => {
  const response = await request.post(END_POINT.login, data);
  return response;
};

export const updateCompany = async (data: ILogin) => {
  const response = await request.put(END_POINT.company, data);
  return response;
};
export const getCompanyInfo = async () => {
  const response = await request.get(END_POINT.company);
  return response;
};

export const getCeoInfo = async () => {
  const response = await request.get(END_POINT.ceo);
  return response;
};

export const updateCeoInfo = async (data: ICeo) => {
  const response = await request.put(END_POINT.ceo, data);
  return response;
};

export const getTeamInfo = async () => {
  const response = await request.get(END_POINT.aboutus);
  return response;
};

export const updateTeamInfo = async (data: ITeamInfo) => {
  const response = await request.put(END_POINT.aboutus, data);
  return response;
};

export const changePassword = async (data: ITeamInfo) => {
  const response = await request.put(END_POINT.user, data);
  return response;
};
export const getCategories = async () => {
  const response = await request.get(END_POINT.categories);
  return response;
};

export const createCategory = async (data: Categories) => {
  const response = await request.post(END_POINT.categories, data);
  return response;
};
export const updateCategory = async (data: Categories) => {
  const response = await request.put(
    `${END_POINT.categories}/${data.id}`,
    data
  );
  return response;
};
export const deleteCategory = async (data: Categories) => {
  const response = await request.delete(`${END_POINT.categories}/${data.id}`);
  return response;
};

export const getProducts = async (id: string) => {
  const response = await request.get(`${END_POINT.categories}/${id}/item`);
  return response;
};

export const createProduct = async (data: CategoryItem) => {
  const response = await request.post(
    `${END_POINT.categories}/${data.id}/item`,
    data
  );
  return response;
};
export const updateProduct = async (data: CategoryItem) => {
  const response = await request.put(`${END_POINT.item}/${data.id}`, data);
  return response;
};
export const deleteProduct = async (data: CategoryItem) => {
  const response = await request.delete(`${END_POINT.item}/${data.id}`);
  return response;
};

export const uploadImage = async (data: any, headers?: any, options?: any) => {
  const response = await request.post(END_POINT.upload, data, headers, options);
  return response;
};
export const getContactUs = async (data: any) => {
  const response = await request.get(END_POINT.contactus, data);
  return response;
};
export const getCUnreadTotal = async () => {
  const response = await request.get(END_POINT.unread);
  return response;
};
export const setRead = async (id: number) => {
  const response = await request.put(`${END_POINT.contactus}/${id}/read`);
  return response;
};
