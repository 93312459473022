import {
  Layout,
  Button,
  Typography,
  Form,
  Input,
  Row,
  notification,
} from "antd";
import "assets/styles/team-info.css";

import Editor from "components/Editor";
import Col from "antd/es/grid/col";
import { useCallback, useEffect, useState } from "react";
import { ITeamInfo } from "service/models";
import { handleError } from "utils/request";
import { getTeamInfo, updateTeamInfo } from "service/service";

const { Title } = Typography;
const { Content } = Layout;

const TeamInfo = () => {
  const [form] = Form.useForm();
  const [data, setData] = useState<ITeamInfo>();
  const getTeam = useCallback(async () => {
    try {
      const res = await getTeamInfo();
      const teamInfo = res.data?.data;
      setData(teamInfo);
      form.setFieldsValue({
        totalUser: teamInfo?.achievement?.totalUser,
        partner: teamInfo?.achievement?.partner,
        connection: teamInfo?.achievement?.connection,
        rating: teamInfo?.achievement?.rating,
      });
    } catch (err) {
      handleError("Get About-Us Failed !", err);
    }
  }, [form]);

  useEffect(() => {
    getTeam();
  }, [getTeam]);

  const handleOnChangeDescription = useCallback(
    (value: string) => {
      if (!data) return;
      setData({
        ...data,
        teamInfo: value,
      });
    },
    [data]
  );

  const onFinish = useCallback(
    async (values: any) => {
      try {
        const dataReq: ITeamInfo = {
          teamInfo: data?.teamInfo,
          achievement: values,
        };
        await updateTeamInfo(dataReq);
        notification.open({
          message: "Update About-Us Success",
          type: "success",
        });
      } catch (err) {
        handleError("Update about-us failed !", err);
      }
    },
    [data?.teamInfo]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="main-content">
      <Content className="pt-24">
        <div className="company-info-form">
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="row-col"
            autoComplete="off"
          >
            <Row>
              <h3>Achievements</h3>
            </Row>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Form.Item name="totalUser">
                  <Input placeholder="Items" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="partner">
                  <Input placeholder="Partner" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="connection">
                  <Input placeholder="HCM & Hanoi Branch" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="rating">
                  <Input placeholder="Warehouse & 1 R&D center" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <h3>Description</h3>
            </Row>
            <Row>
              <Editor
                value={data?.teamInfo}
                onChangeForm={handleOnChangeDescription}
                placeholder="Description Our Team"
              />
            </Row>

            <Row>
              <Col span={2} offset={22} className="mt-48">
                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    SAVE
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </div>
  );
};

export default TeamInfo;
