import { useEffect, useState, useMemo, useCallback } from "react";
import { debounce } from "lodash";
import {
  Table,
  Layout,
  Input,
  Select,
  Space,
  notification,
  Button,
  Typography,
  Tooltip,
} from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { getContactUs, setRead } from "service/service";
import { handleError } from "utils/request";
import { ContactUsDetail, ContactUsItem } from "service/models";

import "assets/styles/contact-us.css";

const { Content } = Layout;
const { Option } = Select;

const ContactUs = () => {
  const [data, setData] = useState<ContactUsDetail>();
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [reGetData, setReGetData] = useState<boolean>(false);
  const handleChangePage = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    async function getContactUsData() {
      try {
        const res = await getContactUs({
          page: page,
          searchText: searchText,
          isRead: filter,
        });
        const data = res?.data?.data;
        setData(data);
      } catch (e) {
        handleError("Get Company Failed !", e);
      }
    }
    getContactUsData();
  }, [filter, page, searchText, reGetData]);

  const onSearch = debounce((event: any) => {
    const vl = event?.target?.value;
    setSearchText(vl);
  }, 500);

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleRead = useCallback(
    async (data: any) => {
      if (data.isRead) return;
      try {
        await setRead(data.id);
        notification.open({
          message: "Contact Us",
          description: "Contact has been marked read successfully.",
          icon: <CheckCircleOutlined style={{ color: "#16a34a" }} />,
        });
        setReGetData(!reGetData);
        window.dispatchEvent(new CustomEvent("refreshUnreadContact"));
      } catch (error) {
        handleError("", error);
      }
    },
    [reGetData]
  );
  const columns: ColumnsType<ContactUsItem> = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "NAME",
        dataIndex: "fullname",
        key: "fullname",
      },
      {
        title: "Email",
        key: "email",
        dataIndex: "email",
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "Note",
        dataIndex: "note",
        key: "note",
        render: (_, record) => {
          return (
            <Tooltip
              placement="top"
              title={record.note}
              overlayStyle={{ maxWidth: "800px" }}
            >
              <Typography.Text ellipsis={true} style={{ width: 400 }}>
                {record.note}
              </Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "read",
        key: "read",
        render: (_, record) => {
          return (
            <Space>
              <Button
                size="small"
                onClick={() => handleRead(record)}
                type={record.isRead ? "primary" : "ghost"}
                style={{
                  cursor: record.isRead ? "auto" : "pointer",
                  pointerEvents: record.isRead ? "none" : "auto",
                }}
              >
                {record.isRead ? "Read" : "Unread"}
              </Button>
            </Space>
          );
        },
      },
    ],
    [handleRead]
  );

  return (
    <div className="main-content" style={{ background: "#fafafa" }}>
      <Content className="pt-24">
        <div className="mb-16">
          <Input
            placeholder="Input search text"
            allowClear
            onChange={onSearch}
            className="contact-us-list-search"
          />
          <Select
            defaultValue=""
            style={{ width: 120, marginLeft: 12 }}
            onChange={handleFilter}
          >
            <Option value="">All</Option>
            <Option value="false">UnRead</Option>
            <Option value="true">Read</Option>
          </Select>
        </div>
        <Table
          columns={columns}
          dataSource={data?.contactUs}
          footer={() => {
            return (
              <div className="contact-us-list-footer">
                <p>Total Item: {data?.total}</p>
                <p>Unread: {data?.unread}</p>
              </div>
            );
          }}
          rowClassName={(record, index) => {
            return record.isRead ? "" : "row-un-read";
          }}
          pagination={
            !data?.totalPage || data?.totalPage <= 1
              ? false
              : {
                  pageSize: data?.pageSize,
                  total: data?.total,
                  current: page,
                  onChange: handleChangePage,
                }
          }
        />
      </Content>
    </div>
  );
};

export default ContactUs;
