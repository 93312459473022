import React from "react";
import { Modal, notification } from "antd";
import { get } from "lodash";
import { AlertOutlined } from "@ant-design/icons";
export const handleError = (title: string, error: any) => {
  if (error.code === "ERR_NETWORK") {
    // return notification.open({
    //   icon: <AlertOutlined style={{ color: "#c91d1d" }} />,
    //   message: "Api is down, Please access later !",
    // });\

    return Modal.error({
      title: <h3>Opps ! Something went wrong</h3>,
      content: (
        <p>
          Can't connect to server
          <br /> Please access admin-portal later !
        </p>
      ),
      icon: <AlertOutlined style={{ color: "#c91d1d" }} />,
      onOk: () => window.location.reload(),
      okText: "Refresh Page",
    });
  }
  const message = get(error, "response.data.data.message", "");
  notification.open({
    message: title,
    description: message,
    icon: <AlertOutlined style={{ color: "#c91d1d" }} />,
  });
  return;
};
