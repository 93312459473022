import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

interface EditorProps {
  placeholder: string;
  value?: string;
  className?: string;
  onChangeForm?: (value: string) => void;
}
export const Editor: React.FC<EditorProps> = ({
  placeholder,
  value,
  className,
  onChangeForm,
}) => {
  const handleChange = (value: any) => {
    if (onChangeForm) onChangeForm(value);
  };
  return (
    <div className={`text-editor ${className}`}>
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
