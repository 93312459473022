import { useRef, useEffect, useState, useCallback, useMemo } from "react";
import {
  Space,
  Table,
  Layout,
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  notification,
  Popconfirm,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  createCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "service/service";
import { handleError } from "utils/request";
import { Categories as CategoriesData } from "service/models";
import { UploadImg } from "components/Upload";
import "assets/styles/categories.css";
import { API_END_POINT } from "configs/endpoint";
import { buildImageURL } from "commons";

const { Content } = Layout;

const Categories = () => {
  const [categories, setCategories] = useState<CategoriesData[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [category, setCategory] = useState<Partial<CategoriesData>>({});
  const isAddRef = useRef<boolean>(false);
  const categoryTitleRef = useRef<string>("");

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const fetchCategories = useCallback(async () => {
    try {
      const res = await getCategories();
      const data = res?.data?.data?.category;
      setCategories(data);
    } catch (e) {
      handleError("Get Company Failed !", e);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectCategory = (category: CategoriesData, e: any) => {
    e?.stopPropagation();
    setCategory(category);
    categoryTitleRef.current = category.title;
    setOpenModal(true);
  };

  const handleDeleteCategory = useCallback(
    async (category: CategoriesData, e: any) => {
      e?.stopPropagation();

      try {
        const res = await deleteCategory(category);
        notification.open({
          message: "Category",
          description: res?.data?.data.message,
          icon: <CheckCircleOutlined style={{ color: "#16a34a" }} />,
        });
        fetchCategories();
      } catch (error) {
        handleError("", error);
      }
    },
    [fetchCategories]
  );

  const handleSubmit = async () => {
    try {
      const value = await form.validateFields();
      const request = isAddRef.current ? createCategory : updateCategory;
      const res = await request({
        ...value,
        id: category.id,
        iconUrl: category.iconUrl,
        imgUrl: category.imgUrl,
      });

      notification.open({
        message: "Category",
        description: res?.data?.data.message,
        icon: <CheckCircleOutlined style={{ color: "#16a34a" }} />,
      });
      setOpenModal(false);
      fetchCategories();
    } catch (error: any) {
      if (!error.values) {
        handleError("", error);
      }
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setCategory({});
    isAddRef.current = false;
  };
  const title = isAddRef.current
    ? "Add New Category"
    : `Edit ${categoryTitleRef.current}`;

  const uploadIconButton = (
    <div>
      {<UploadOutlined />}
      <div>Icon</div>
    </div>
  );

  const uploadImageButton = (
    <div>
      {<UploadOutlined />}
      <div>Thumbnails</div>
    </div>
  );

  useEffect(() => {
    if (!openModal) return;
    form.setFieldsValue(category);
  }, [category, form, openModal]);

  const handleUploadSuccess = (url: string, type: string) => {
    const formValue = form.getFieldsValue();
    setCategory({
      ...category,
      ...formValue,
      [type]: url,
    });
  };

  const handleRemoveImage = (type: string) => {
    const formValue = form.getFieldsValue();
    setCategory({
      ...category,
      ...formValue,
      [type]: "",
    });
  };

  const columns: ColumnsType<CategoriesData> = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "NAME",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "ICON",
        key: "iconUrl",
        dataIndex: "iconUrl",
        render: (iconUrl) => <img src={`${API_END_POINT}${iconUrl}`} alt="" />,
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "description",
      },

      {
        title: "ACTION",
        key: "action",
        render: (_, record) => (
          <Space size="middle" onClick={(e) => e.stopPropagation()}>
            <EditFilled
              className="category-edit"
              title="Edit"
              onClick={(e) => handleSelectCategory(record, e)}
            />
            <Popconfirm
              title={`Are you sure delete category: ${record.title}`}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={(e) => handleDeleteCategory(record, e)}
              placement="leftTop"
              overlayClassName="category-popup-delete"
              onCancel={(e) => e?.stopPropagation()}
              okButtonProps={{
                size: "small",
              }}
              cancelButtonProps={{
                size: "small",
              }}
            >
              <DeleteOutlined className="category-delete" title="Delete" />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [handleDeleteCategory]
  );

  return (
    <div className="main-content">
      <Content className="pt-24">
        <div className="category-create">
          <Button
            type="primary"
            onClick={(e) => {
              isAddRef.current = true;
              handleSelectCategory(
                {
                  description: "",
                  title: "",
                } as CategoriesData,
                e
              );
            }}
          >
            <PlusOutlined /> Create
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={categories}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate(`/categories/${record.id}`);
              },
            };
          }}
        />

        <Modal
          title={title}
          open={openModal}
          onOk={handleSubmit}
          onCancel={handleCancel}
          width={778}
          maskClosable={false}
          destroyOnClose
        >
          <Form
            form={form}
            size="large"
            initialValues={category}
            name="form-category"
          >
            <Row>
              <Col span={16}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Category name!",
                    },
                  ]}
                >
                  <Input placeholder="Category name" name="title" />
                </Form.Item>
              </Col>
              <Col span={6} offset={1}>
                <Form.Item valuePropName="fileList">
                  <UploadImg
                    listType="picture-card"
                    className="category-upload-icon"
                    maxCount={1}
                    name="icon"
                    value={{
                      name: "",
                      imgUrl: buildImageURL(category.iconUrl),
                    }}
                    iconUploadRender={uploadIconButton}
                    onUploadSuccess={(url) =>
                      handleUploadSuccess(url, "iconUrl")
                    }
                    accept="image/*"
                    onRemove={() => handleRemoveImage("iconUrl")}
                  ></UploadImg>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input your Category descriptions!",
                },
              ]}
            >
              <Input.TextArea
                placeholder="Category description"
                rows={4}
                name="description"
              />
            </Form.Item>

            <Form.Item valuePropName="fileList">
              <UploadImg
                value={{
                  name: "",
                  imgUrl: buildImageURL(category.imgUrl),
                }}
                listType="picture-card"
                className="thumbnails-icon"
                name="image"
                accept="image/*"
                maxCount={1}
                iconUploadRender={uploadImageButton}
                onUploadSuccess={(url) => handleUploadSuccess(url, "imgUrl")}
                onRemove={() => handleRemoveImage("imgUrl")}
              ></UploadImg>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </div>
  );
};

export default Categories;
