import {
  Layout,
  Button,
  Typography,
  Form,
  Input,
  Row,
  notification,
} from "antd";
import "assets/styles/ceo.css";

import Editor from "components/Editor";
import Col from "antd/es/grid/col";
import { UploadImg } from "components/Upload";
import { useCallback, useEffect, useState } from "react";
import { handleError } from "utils/request";
import { getCeoInfo, updateCeoInfo } from "service/service";
import { ICeo } from "service/models";
import { API_END_POINT } from "configs/endpoint";
import { buildImageURL } from "commons";

const { Title } = Typography;
const { Content } = Layout;

const Ceo = () => {
  const [data, setData] = useState<ICeo | null>();
  const [form] = Form.useForm();

  const getCeo = useCallback(async () => {
    try {
      const res: any = await getCeoInfo();
      const ceoInfo = res?.data?.data;
      setData(ceoInfo);
      form.setFieldsValue({
        name: ceoInfo.name,
      });
    } catch (err) {
      handleError("Get Ceo Information Failed !", err);
    }
  }, [form]);

  useEffect(() => {
    getCeo();
  }, [getCeo]);

  const onFinish = useCallback(
    async (values: any) => {
      try {
        if (!data) return;
        const newData = {
          ...data,
          ...values,
        };
        setData(newData);
        await updateCeoInfo(newData);
        notification.open({
          message: "Update ceo information success",
          type: "success",
        });
      } catch (err) {
        handleError("Upload Ceo Fail", err);
      }
    },
    [data]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleUploadSuccess = useCallback(
    (imgUrl: string) => {
      if (!data) return;
      setData({
        ...data,
        imgUrl: imgUrl,
      });
    },
    [data]
  );
  const handleOnChange = useCallback(() => {}, []);

  const handleOnChangeDescription = useCallback(
    (value: string) => {
      if (!data) return;
      setData({
        ...data,
        description: value,
      });
    },
    [data]
  );

  return (
    <div className="main-content">
      <Content className="pt-24">
        <div className="company-info-form">
          <Form
            name="basic"
            onFinish={onFinish}
            onChange={handleOnChange}
            onFinishFailed={onFinishFailed}
            className="row-col"
            autoComplete="off"
            form={form}
          >
            <Row>
              <Col span={24}>
                <UploadImg
                  value={{
                    imgUrl: buildImageURL(data?.imgUrl),
                    name: "ceo",
                  }}
                  onUploadSuccess={handleUploadSuccess}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} className="mt-16">
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Ceo name!",
                    },
                  ]}
                >
                  <Input placeholder="Ceo name" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Editor
                  onChangeForm={handleOnChangeDescription}
                  value={data?.description}
                  placeholder="Description"
                />
              </Col>
            </Row>
            <Row>
              <Col span={2} offset={22}>
                <Form.Item>
                  <Button
                    style={{ width: "100%", marginTop: "32px" }}
                    type="primary"
                    htmlType="submit"
                  >
                    SAVE
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </div>
  );
};

export default Ceo;
