import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import Main from "components/layout/Main";

interface ProtectedRouteProps {
  children: React.ReactNode;
}
const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  children,
}) => {
  const { user } = useAuth() || {};

  if (!user) {
    return <Navigate to="/sign-in" />;
  }
  return <Main>{children}</Main>;
};
export default ProtectedRoute;
