import {
  Layout,
  Button,
  Typography,
  Form,
  Input,
  Row,
  notification,
} from "antd";
import "assets/styles/company.css";

import Editor from "components/Editor";
import Col from "antd/es/grid/col";
import TextArea from "antd/lib/input/TextArea";
import { EMAIL_REGEX } from "configs/regex";
import { useCallback, useEffect, useState } from "react";
import { getCompanyInfo, updateCompany } from "service/service";
import { handleError } from "utils/request";

const { Content } = Layout;

const Company = () => {
  const [form] = Form.useForm();
  const [description, setDescription] = useState<string>();

  const getCompany = useCallback(async () => {
    try {
      const res = await getCompanyInfo();
      const companyInfo = res?.data?.data;
      setDescription(companyInfo?.description);
      form.setFieldsValue({
        phone1: companyInfo.contact.phone1,
        phone2: companyInfo.contact.phone2,
        email: companyInfo.contact.email,
        address: companyInfo.address,
        slogan: companyInfo.slogan,
        warehouse: companyInfo?.profileInfo?.warehouse,
        trustCompany: companyInfo?.profileInfo?.trustCompany,
        experience: companyInfo?.profileInfo?.experience,
        client: companyInfo?.profileInfo.client,
      });
    } catch (e) {
      handleError("Get Company Failed !", e);
    }
  }, [form]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  const onFinish = useCallback(
    async (form: any) => {
      try {
        const dataRequest: any = {
          contact: {
            phone1: form.phone1,
            phone2: form.phone2,
            email: form.email,
          },
          slogan: form.slogan,
          address: form.address,
          description: description,
          profileInfo: {
            warehouse: form.warehouse,
            trustCompany: form.trustCompany,
            experience: form.experience,
            client: form.client,
          },
        };
        await updateCompany(dataRequest);
        notification.open({
          message: "Update Company Success",
          type: "success",
        });
      } catch (e) {
        handleError("Update Company Failed !", e);
      }
    },
    [description]
  );

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleOnChangeEditor = useCallback((value: string) => {
    setDescription(value);
  }, []);

  return (
    <div className="main-content">
      <Content>
        <div className="company-info-form">
          <Form
            name="basic"
            onFinishFailed={onFinishFailed}
            onFinish={onFinish}
            className="row-col"
            autoComplete="off"
            form={form}
          >
            <Row>
              <h3>Information</h3>
            </Row>
            <Row gutter={[8, 0]}>
              <Col span={12}>
                <Form.Item
                  name="phone1"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Phone 1",
                    },
                  ]}
                >
                  <Input placeholder="Phone 1" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="phone2">
                  <Input placeholder="Phone 2" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Address",
                    },
                  ]}
                >
                  <Input placeholder="Address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email",
                    },
                    {
                      pattern: EMAIL_REGEX,
                      message: "Please input correct format email",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col span={24}>
                <h3>Achievement</h3>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="warehouse"
                  rules={[
                    {
                      required: true,
                      message: "Please input your warehouse",
                    },
                  ]}
                >
                  <Input placeholder="Warehouse" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="experience"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Experience Years",
                    },
                  ]}
                >
                  <Input placeholder="Experience Years" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="client"
                  rules={[
                    {
                      required: true,
                      message: "Please input your total Client",
                    },
                  ]}
                >
                  <Input placeholder="Total Client" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="trustCompany"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Trust Company number",
                    },
                  ]}
                >
                  <Input placeholder="Trust Company Number" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[8, 0]}>
              <Col span={24}>
                <h3>Description</h3>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="slogan"
                  rules={[
                    { required: true, message: "Please input your Slogan!" },
                  ]}
                >
                  <TextArea placeholder="Slogan" rows={4} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Editor
                  placeholder="Description"
                  value={description}
                  onChangeForm={handleOnChangeEditor}
                />
              </Col>
            </Row>

            <Row>
              <Col span={2} offset={22} className="mt-48">
                <Form.Item>
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    htmlType="submit"
                  >
                    SAVE
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </div>
  );
};

export default Company;
