import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { API_END_POINT } from "configs/endpoint";

export enum HTTP_METHOD {
  PUT = "put",
  POST = "post",
  GET = "get",
  DELETE = "delete",
}

class RequestService {
  private request = async (
    method: HTTP_METHOD,
    url: string,
    data: Record<string, any> | undefined,
    headers?: AxiosRequestHeaders,
    options?: AxiosRequestConfig
  ) => {
    const requestBodyKey = method === "get" ? "params" : "data";
    try {
      const fullUrl = `${API_END_POINT}${url}`;
      const response = await axios.request({
        method,
        [requestBodyKey]: data,
        headers: {
          Authorization: `${localStorage.getItem("accessToken")}`,
          "Access-Control-Allow-Origin": "*",
          ...headers,
        },
        url: fullUrl,
        ...options,
      });
      return response;
    } catch (errors: any) {
      if (errors.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      throw errors;
    }
  };

  post = async (
    url: string,
    data?: Record<string, any>,
    headers?: AxiosRequestHeaders,
    options?: AxiosRequestConfig
  ) => {
    return this.request(HTTP_METHOD.POST, url, data, headers, options);
  };
  get = async (
    url: string,
    data?: Record<string, any>,
    headers?: AxiosRequestHeaders,

    options?: AxiosRequestConfig
  ) => {
    return this.request(HTTP_METHOD.GET, url, data, headers, options);
  };
  put = async (
    url: string,
    data?: Record<string, any>,
    headers?: AxiosRequestHeaders,
    options?: AxiosRequestConfig
  ) => {
    return this.request(HTTP_METHOD.PUT, url, data, headers, options);
  };
  delete = async (
    url: string,
    data?: Record<string, any>,
    headers?: AxiosRequestHeaders,
    options?: AxiosRequestConfig
  ) => {
    return this.request(HTTP_METHOD.DELETE, url, data, headers, options);
  };
}

export const request = new RequestService();
