import { Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
} from "antd";
import signinbg from "../assets/images/img-signin.jpg";
import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  FacebookFilled,
} from "@ant-design/icons";
import { useState } from "react";
import { useAuth } from "hooks/useAuth";
import { loginService } from "service/service";
import { handleError } from "utils/request";
import { AUTH_KEY } from "configs/auth";

const FOOTER_SOCIAL = [
  {
    key: 0,
    label: <Link to="#">{<FacebookFilled />}</Link>,
  },
  {
    key: 1,
    label: <Link to="#">{<InstagramOutlined />}</Link>,
  },
  {
    key: 2,
    label: <Link to="#">{<TwitterOutlined />}</Link>,
  },
  {
    key: 3,
    label: <Link to="#">{<DribbbleOutlined />}</Link>,
  },
];

const { Title } = Typography;
const { Header, Footer, Content } = Layout;

const SignIn = () => {
  const [disabled, setDisabled] = useState<boolean>(true);
  const [form] = Form.useForm();
  const auth = useAuth();

  const handleLogin = async (values: any) => {
    try {
      //call api to login
      const response = await loginService(values);
      const accessToken = response.data.data.accessToken;
      localStorage.setItem("accessToken", `${AUTH_KEY} ${accessToken}`);
      if (accessToken) {
        auth?.authenticationSuccess("dung");
      }
    } catch (error: any) {
      handleError("Login Fail", error);
    }
  };

  const handleFormChange = () => {
    const hasErrors =
      !form.isFieldsTouched(["username"]) ||
      !form.isFieldsTouched(["password"]) ||
      form.getFieldsError().some(({ errors }) => errors.length);
    setDisabled(hasErrors);
  };

  return (
    <Layout className="layout-default layout-signin">
      <Header>
        <div className="header-col header-brand">
          <h5>AIC Dashboard</h5>
        </div>
      </Header>
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xs={{ span: 24, offset: 0 }}
            lg={{ span: 6, offset: 2 }}
            md={{ span: 12 }}
          >
            <Title className="mb-15">Sign In</Title>
            <Title className="font-regular text-muted" level={5}>
              Enter your email and password to sign in
            </Title>
            <Form
              name="signin"
              onFinish={handleLogin}
              layout="vertical"
              className="row-col"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              form={form}
              onFieldsChange={handleFormChange}
            >
              <Form.Item
                className="username"
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="username" autoComplete="username" />
              </Form.Item>

              <Form.Item
                className="username"
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input
                  placeholder="Password"
                  type="password"
                  autoComplete="current-password"
                />
              </Form.Item>

              <Form.Item
                name="remember"
                className="aligin-center"
                valuePropName="checked"
              >
                <Checkbox> Remember me</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                  disabled={disabled}
                >
                  SIGN IN
                </Button>
              </Form.Item>
              {/* <p className="font-semibold text-muted">
                Don't have an account?{" "}
                <Link to="/sign-up" className="text-dark font-bold">
                  Sign Up
                </Link>
              </p> */}
            </Form>
          </Col>
          <Col
            className="sign-img"
            style={{ padding: 12 }}
            xs={{ span: 24 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
          >
            <img src={signinbg} alt="" />
          </Col>
        </Row>
      </Content>
      <Footer>
        <Menu
          mode="horizontal"
          className="menu-nav-social"
          items={FOOTER_SOCIAL}
        ></Menu>
        <p className="copyright">Copyright © 2022 AIC Company</p>
      </Footer>
    </Layout>
  );
};

export default SignIn;
