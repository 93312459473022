import { createContext, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "hooks/useLocalStorage";

interface AuthContextData {
  user: any;
  authenticationSuccess: (data: any) => void;
  logout: () => void;
}
interface AuthProviderProps {
  children?: React.ReactNode;
}
const AuthContext = createContext<AuthContextData | null>(null);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const authenticationSuccess = (data: any) => {
    setUser(data);
    navigate("/dashboard");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/sign-in", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      authenticationSuccess,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
