import {
  useState,
  memo,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import "assets/styles/material.css";
import { Button, Input, message, Tooltip } from "antd";
interface MaterialProps {
  showAddButton?: boolean;
  showNumber?: number;
  onDataChange?: (data: string[], addData: string) => void;
  allowRemove?: boolean;
  data: string[];
}

interface MaterialItemProps {
  data: string;
  onRemove: (data: string) => void;
  allowRemove?: boolean;
}

const MaterialItem: React.FC<MaterialItemProps> = ({
  data,
  onRemove,
  allowRemove,
}) => {
  return (
    <div
      onClick={() => {
        if (!allowRemove) return;
        onRemove(data);
      }}
      className="material-item"
    >
      <span className="material-item-data">{data}</span>
      {allowRemove && (
        <CloseOutlined className="material-item-action" title="remove" />
      )}
    </div>
  );
};

const Material: React.ForwardRefRenderFunction<
  HTMLDivElement,
  MaterialProps
> = (
  { showAddButton, showNumber, onDataChange, allowRemove, data: dataProp },
  ref
) => {
  const [data, setData] = useState<string[]>(dataProp);
  const [value, setValue] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setData(dataProp);
  }, [dataProp]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setValue(value);
  };

  const handleAdd = (action: string, e: any) => {
    if ((action === "keydown" && e?.code !== "Enter") || !value) return;
    if (data.includes(value)) {
      message.error("Element already exists!");
      return;
    }
    const newData = [...data, value];
    setData(newData);
    onDataChange?.(newData, value);
    setValue("");
  };

  const handleRemove = (itemDelete: string) => {
    const newData = [...data];
    const index = newData.indexOf(itemDelete);
    newData.splice(index, 1);
    setData(newData);
  };
  const dataShow = showNumber ? data.slice(0, showNumber) : data;

  const getData = () => {
    return data;
  };

  const validate = () => {
    if (data.length > 0) {
      setShowError(false);
      return true;
    }
    setShowError(true);
    return false;
  };
  useImperativeHandle(ref, () => ({
    ...containerRef.current!,
    getData: getData,
    validate: validate,
  }));

  return (
    <div className="material-container" ref={containerRef}>
      {dataShow.map((m, i) => {
        return (
          <MaterialItem
            data={m}
            onRemove={handleRemove}
            key={`${m}-${i}`}
            allowRemove={allowRemove}
          />
        );
      })}
      {showNumber && <span className="material-more">. . .</span>}
      {showAddButton && (
        <div>
          <Input.Group compact className="material-add-item-action-container">
            <Input
              className="material-add-item"
              placeholder="Add new description"
              onChange={handleChange}
              value={value}
              onKeyDown={(e) => handleAdd("keydown", e)}
            />
            <Tooltip title="Add">
              <Button
                className="material-add-item-action"
                onClick={(e) => handleAdd("click", e)}
                disabled={!value}
              >
                <PlusOutlined />
              </Button>
            </Tooltip>
          </Input.Group>
          {showError && data.length < 1 && (
            <div style={{ color: "#ff4d4f" }}>Please input description!</div>
          )}
        </div>
      )}
    </div>
  );
};

const MemoForwardMaterial = forwardRef(Material);
const MemoMaterial = memo(MemoForwardMaterial);

export default MemoMaterial;
