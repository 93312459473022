import { useRef, useEffect, useState, useCallback, useMemo } from "react";
import {
  Space,
  Table,
  Layout,
  Button,
  Modal,
  Form,
  Input,
  Row,
  Col,
  notification,
  Popconfirm,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  EditFilled,
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  createProduct,
  deleteProduct,
  getProducts,
  updateProduct,
} from "service/service";
import { handleError } from "utils/request";
import { Categories as CategoriesData, CategoryItem } from "service/models";
import { UploadImg } from "components/Upload";
import "assets/styles/categories.css";
import Material from "components/Material";
import { useParams } from "react-router-dom";
import { API_END_POINT } from "configs/endpoint";
import { buildImageURL } from "commons";

const { Content } = Layout;

const CategoryItems = () => {
  const [products, setProducts] = useState<CategoryItem[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [product, setProduct] = useState<Partial<CategoryItem>>({});
  const isAddRef = useRef<boolean>(false);
  const materialRef = useRef<HTMLDivElement>(null);
  const productTitleRef = useRef<string>("");
  const { categoryId } = useParams();
  const [form] = Form.useForm();

  const fetchProducts = useCallback(async () => {
    try {
      const res = await getProducts(categoryId as string);
      const data = res?.data?.data?.item;
      setProducts(data);
    } catch (e) {
      handleError("Get Company Failed !", e);
    }
  }, [categoryId]);

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectProduct = (item: CategoryItem) => {
    setProduct(item);
    productTitleRef.current = item.title;
    setOpenModal(true);
  };
  const handleDeleteCategory = useCallback(
    async (item: CategoryItem) => {
      try {
        const res = await deleteProduct(item);
        notification.open({
          message: "Product",
          description: res?.data?.data.message,
          icon: <CheckCircleOutlined style={{ color: "#16a34a" }} />,
        });
        fetchProducts();
      } catch (error) {
        handleError("", error);
      }
    },
    [fetchProducts]
  );

  const handleSubmit = async () => {
    const description = (materialRef.current as any).getData();
    try {
      const isDesValid = (materialRef.current as any).validate();
      const value = await form.validateFields();
      if (!isDesValid) {
        return;
      }
      const request = isAddRef.current ? createProduct : updateProduct;
      const res = await request({
        ...value,
        id: product.id || categoryId,
        imgUrl: product.imgUrl,
        description: description.join(","),
      });

      notification.open({
        message: "Product",
        description: res?.data?.data.message,
        icon: <CheckCircleOutlined style={{ color: "#16a34a" }} />,
      });
      setOpenModal(false);
      fetchProducts();
    } catch (error: any) {
      if (!error.values) {
        handleError("", error);
      }
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setProduct({});
    isAddRef.current = false;
  };
  const title = isAddRef.current
    ? "Add New Product"
    : `Edit ${productTitleRef.current}`;

  const uploadImageButton = (
    <div>
      {<UploadOutlined />}
      <div>Thumbnails</div>
    </div>
  );

  useEffect(() => {
    if (!openModal) return;
    form.setFieldsValue(product);
  }, [form, openModal, product]);

  const handleUploadSuccess = (url: string) => {
    const formValue = form.getFieldsValue();
    const description = (materialRef.current as any).getData();
    setProduct({
      ...product,
      ...formValue,
      description: description.length > 0 ? description.join(",") : "",
      imgUrl: url,
    });
  };

  const handleRemoveImage = () => {
    const formValue = form.getFieldsValue();
    setProduct({
      ...product,
      ...formValue,
      imgUrl: "",
    });
  };
  const columns: ColumnsType<CategoryItem> = useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "NAME",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        key: "description",
        render: (_, record) => (
          <Material showNumber={8} data={record.description.split(",")} />
        ),
      },

      {
        title: "ACTION",
        key: "action",
        render: (_, record) => (
          <Space size="middle">
            <EditFilled
              className="category-edit"
              title="Edit"
              onClick={() => {
                isAddRef.current = false;
                handleSelectProduct(record);
              }}
            />
            <Popconfirm
              title={`Are you sure delete: ${record.title}`}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleDeleteCategory(record)}
              placement="leftTop"
              overlayClassName="category-popup-delete"
              okButtonProps={{
                size: "small",
              }}
              cancelButtonProps={{
                size: "small",
              }}
            >
              <DeleteOutlined className="category-delete" title="Delete" />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [handleDeleteCategory]
  );

  return (
    <div className="main-content">
      <Content className="pt-24">
        <div className="category-create">
          <Button
            type="primary"
            onClick={() => {
              isAddRef.current = true;
              handleSelectProduct({
                title: "",
              } as CategoriesData);
            }}
          >
            <PlusOutlined /> Create
          </Button>
        </div>

        <Table columns={columns} dataSource={products} />

        <Modal
          title={title}
          open={openModal}
          onOk={handleSubmit}
          onCancel={handleCancel}
          width={778}
          maskClosable={false}
          destroyOnClose
        >
          <Form
            form={form}
            size="large"
            initialValues={product}
            name="form-product"
          >
            <Row>
              <Col span={16}>
                <Form.Item
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Product name!",
                    },
                  ]}
                >
                  <Input placeholder="Product name" name="title" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="description">
              <Material
                data={
                  product.description ? product.description?.split(",") : []
                }
                showAddButton
                allowRemove
                ref={materialRef}
              />
            </Form.Item>

            <Form.Item valuePropName="fileList">
              <UploadImg
                value={{
                  name: "",
                  imgUrl: buildImageURL(product.imgUrl),
                }}
                listType="picture-card"
                className="thumbnails-icon"
                name="files"
                maxCount={1}
                iconUploadRender={uploadImageButton}
                onUploadSuccess={handleUploadSuccess}
                onRemove={handleRemoveImage}
                accept="image/*"
              ></UploadImg>
            </Form.Item>
          </Form>
        </Modal>
      </Content>
    </div>
  );
};

export default CategoryItems;
