import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

import { AuthProvider } from "./hooks/useAuth";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import ProtectedRoute from "components/ProtectedRoute";
import Company from "pages/Company";
import Ceo from "pages/Ceo";
import TeamInfo from "pages/TeamInfo";
import Categories from "pages/Categories";
import CategoryItems from "pages/CategoryItems";
import ContactUs from "pages/ContactUs";
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />

          <Route
            path="/company"
            element={
              <ProtectedRoute>
                <Company />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ceo"
            element={
              <ProtectedRoute>
                <Ceo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/team-info"
            element={
              <ProtectedRoute>
                <TeamInfo />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories"
            element={
              <ProtectedRoute>
                <Categories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/categories/:categoryId"
            element={
              <ProtectedRoute>
                <CategoryItems />
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact-us"
            element={
              <ProtectedRoute>
                <ContactUs />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Navigate to="/company" />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
