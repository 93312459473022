import { Menu, Button, MenuProps, Badge } from "antd";
import {
  HomeOutlined,
  TeamOutlined,
  AppstoreOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "assets/styles/sidebar.css";
import { useEffect, useMemo, useState } from "react";
import { getCUnreadTotal } from "service/service";
import { handleError } from "utils/request";

interface SideNavProps {
  color: string;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const getDefaultOpenKey: any = {
  company: "home",
  ceo: "about-us",
  "team-info": "about-us",
  categories: "application-service",
  "contact-us": "contact-us",
};
const SideNav: React.FunctionComponent<SideNavProps> = ({ color }) => {
  const [contactUnread, setContactUnread] = useState<number>(0);
  const { pathname } = useLocation();
  const page = pathname.split("/")[1];

  const getContactUnread = async () => {
    try {
      const res = await getCUnreadTotal();
      setContactUnread(res?.data?.data?.total);
    } catch (error) {
      handleError("Get Contact Unread Failed !", error);
    }
  };

  useEffect(() => {
    getContactUnread();
  }, []);

  useEffect(() => {
    window.addEventListener("refreshUnreadContact", getContactUnread);
    return () =>
      window.removeEventListener("refreshUnreadContact", getContactUnread);
  });
  const items: MenuItem[] = useMemo(
    () => [
      getItem("Home", "home", <HomeOutlined />, [
        getItem(<Link to="/company">Company</Link>, "sidebar.company"),
      ]),
      getItem("About Us", "about-us", <TeamOutlined />, [
        getItem(<Link to="/ceo">Ceo</Link>, "sidebar.ceo"),
        getItem(<Link to="/team-info">Team Info</Link>, "sidebar.team-info"),
      ]),
      getItem(
        "Application & Service",
        "application-service",
        <AppstoreOutlined />,
        [
          getItem(
            <Link to="/categories">Categories</Link>,
            "sidebar.categories"
          ),
        ]
      ),
      getItem(
        <Link to="/contact-us">
          Contact Us{" "}
          <Badge
            count={contactUnread}
            style={{
              marginLeft: 12,
            }}
          />
        </Link>,
        "sidebar.contact-us",
        <ContactsOutlined />
      ),
    ],
    [contactUnread]
  );
  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      <Menu
        inlineCollapsed={true}
        style={{ width: 256 }}
        defaultSelectedKeys={[`sidebar.${page}`]}
        defaultOpenKeys={[getDefaultOpenKey[page]]}
        mode="inline"
        items={items}
        className="aic-navbar-menu"
      />
    </>
  );
};

export default SideNav;
