export const API_END_POINT = process.env.REACT_APP_API_ENDPOINT;

export const END_POINT = {
  login: "/api/v1/login",
  company: "/api/v1/company",
  upload: "/api/v1/image",
  ceo: "/api/v1/ceo",
  aboutus: "/api/v1/aboutus",
  user: "/api/v1/user",
  categories: "/api/v1/category",
  item: "/api/v1/item",
  contactus: "/api/v1/contact-us",
  unread: "/api/v1/contact-us/unread",
};
