import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload, Modal, UploadFile, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import { buildImageURL } from "commons";
import { API_END_POINT } from "configs/endpoint";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { uploadImage } from "service/service";
import { handleError } from "utils/request";

export interface IUpload extends UploadProps {
  onUploadSuccess?: (url: string) => void;
  onUploadFail?: () => void;
  value?: any;
  iconUploadRender?: React.ReactNode;
}

export const UploadImg: React.FC<IUpload> = ({
  onUploadFail,
  onUploadSuccess,
  listType = "picture",
  value,
  iconUploadRender,
  name,
  ...props
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<any>([]);

  useEffect(() => {
    if (!value.imgUrl) return;
    if (value?.imgUrl === fileList[0]?.thumbUrl) return;
    setFileList([
      {
        url: value?.imgUrl,
        name: value?.name,
        status: "done",
        thumbUrl: value?.imgUrl,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = useCallback(
    (info: any) => {
      let newFileList: any[] = [];
      info.fileList.forEach((file: any) => {
        const newThumbUrl = file?.response?.thumbUrl;
        newFileList.push({
          ...file,
          thumbUrl: buildImageURL(newThumbUrl, file.thumbUrl),
        });
      });
      setFileList(newFileList);
      if (info.file.status !== "uploading") {
        message.info(info.file, info.fileList);
      }
      // info.file.originFileObj.name = "ceo";
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        onUploadSuccess && onUploadSuccess(info.file.response?.url);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        onUploadFail && onUploadFail();
      }
    },
    [onUploadFail, onUploadSuccess]
  );

  const handleCancel = () => setPreviewOpen(false);
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleUpload = useCallback(async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;

    const fmData = new FormData();

    fmData.append("files", file);

    try {
      const response = await uploadImage(fmData, null, {
        onUploadProgress: (event: any) => {
          onProgress({ percent: (event.loaded / event.total) * 100 });
        },
      });
      onSuccess(response.data);
    } catch (err) {
      handleError("Upload Fail", err);
      onError({ err });
    }
  }, []);

  return (
    <Fragment>
      <Upload
        multiple={false}
        fileList={fileList}
        listType={listType}
        onChange={handleOnChange}
        onPreview={handlePreview}
        {...props}
        customRequest={handleUpload}
      >
        {iconUploadRender && fileList?.length < 1 ? (
          iconUploadRender
        ) : fileList?.length >= 1 ? null : (
          <Button name="file" icon={<UploadOutlined />}>
            Upload
          </Button>
        )}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Fragment>
  );
};
