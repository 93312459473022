import { Button, Col, Form, Input, notification, Row } from "antd";
// import { EMAIL_REGEX } from "configs/regex";
import React, { useCallback } from "react";
import { changePassword } from "service/service";
import { handleError } from "utils/request";

export const UpdateProfile: React.FC<any> = () => {
  const handleOnFinish = useCallback(async (value: any) => {
    try {
      await changePassword(value);
      notification.open({
        message: "Change Password success",
      });
    } catch (err) {
      handleError("Change Password Failed", err);
    }
  }, []);
  return (
    <Form
      name="basic"
      className="row-col"
      autoComplete="off"
      style={{ width: "100%" }}
      onFinish={handleOnFinish}
    >
      <Row className="mt-16">
        {/* <Col span={24}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email",
              },
              {
                pattern: EMAIL_REGEX,
                message: "Please input correct format email",
              },
            ]}
          >
            <Input autoComplete="none" placeholder="Email" />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password",
              },
            ]}
          >
            <Input autoComplete="none" type="password" placeholder="Password" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: "Please input your Confirm Password",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Confirm password doesn't match!");
                },
              }),
            ]}
          >
            <Input
              type="password"
              autoComplete="none"
              placeholder="Confirm Password"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              SAVE
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
